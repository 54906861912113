<template>
  <div>
    <a href="#" @click.prevent="visible = true">
      {{ $t('pages.terms_of_service.actions.open_modal') }}
    </a>

    <portal to="modals">
      <t-modal v-if="visible" size="sm" @modal-close="handleClose">
        <a href="#" class="t-modal__close" role="button" @click.prevent="handleClose">
          <t-icon icon="times" />
        </a>
        <div class="t-modal__header">
          <span class="t-modal__title">
            {{ $t('pages.terms_of_service.actions.update_legal_guardian') }}
          </span>
        </div>
        <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
          <t-form @submit.native.prevent="handleSubmit(submit)">
            <div class="t-modal__body">
              <t-validation-wrapper
                vid="firstname"
                :name="$t('fields.guardian.firstname')"
                rules="required"
              >
                <t-input
                  v-model="form.firstname"
                  type="text"
                  :placeholder="$t('fields.guardian.firstname')"
                >
                  <t-icon slot="right" :icon="['fas', 'user']" />
                </t-input>
              </t-validation-wrapper>
              <t-validation-wrapper
                vid="lastname"
                :name="$t('fields.guardian.lastname')"
                rules="required"
              >
                <t-input
                  v-model="form.lastname"
                  type="text"
                  :placeholder="$t('fields.guardian.lastname')"
                >
                  <t-icon slot="right" :icon="['fas', 'user']" />
                </t-input>
              </t-validation-wrapper>
              <t-validation-wrapper
                vid="email"
                :name="$t('fields.guardian.email')"
                rules="required|email"
              >
                <t-input
                  v-model="form.email"
                  type="email"
                  :placeholder="`${$t('fields.guardian.email')}...`"
                >
                  <t-icon slot="right" :icon="['far', 'envelope']" />
                </t-input>
              </t-validation-wrapper>
              <t-validation-wrapper
                vid="gender"
                :name="$t('fields.guardian.gender')"
                rules="required|string"
              >
                <gender-select v-model="form.gender" />
              </t-validation-wrapper>
              <t-form-element>
                <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
                  {{ $t('global.actions.save') }}
                </t-button-loading>
              </t-form-element>
            </div>
          </t-form>
        </validation-observer>
      </t-modal>
    </portal>
  </div>
</template>
<script>
import TValidationWrapper from '~/components/TValidationWrapper';
import GenderSelect from '~/components/EnumSelect/GenderSelect';
import LegalContact from '~/models/LegalContact';

export default {
  components: {
    TValidationWrapper,
    GenderSelect,
  },

  props: {
    legalContact: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: null,

    visible: false,
    loading: false,
  }),

  created() {
    this.form = this.getForm(this.legalContact);
  },

  methods: {
    getForm(model) {
      return {
        firstname: model?.firstname,
        lastname: model?.lastname,
        email: model?.email,
        gender: model?.gender,
      };
    },

    async submit() {
      this.visible = true;
      this.loading = true;
      try {
        const legalContact = await this.update();
        this.$emit('updated', legalContact);

        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async update() {
      const legalContact = await new LegalContact({
        id: this.legalContact.id,
        ...this.form,
      }).patch();

      await this.$notify.success(this.$t('notifications.legal_guardian.updated'));

      return legalContact;
    },

    handleClose() {
      this.visible = false;
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.modal-create-invite {
}
</style>
