<template>
  <TLoading v-if="loading" />
  <t-split-screen v-else centered>
    <nuxt-link class="logout" :to="{ name: $RouteEnum.LOGOUT }">
      {{ $t('global.actions.logout') }}
    </nuxt-link>
    <div v-if="isAdult">
      <h1>{{ $t('pages.terms_of_service.heading') }}</h1>
      <p class="text--grey">
        {{ $t('pages.terms_of_service.adult.lead') }}
      </p>
      <accept-tos-form @accepted="onAccepted" />
    </div>
    <template v-else>
      <div v-if="!legalContact">
        <h1>{{ $t('pages.terms_of_service.minor.heading') }}</h1>
        <p class="text--grey">
          {{ $t('pages.terms_of_service.minor.lead') }}
        </p>
        <create-legal-contact-form @created="onLegalContactCreated" />
      </div>
      <div v-else style="display: flex">
        <t-icon icon="hourglass" boxed type="info" style="flex-shrink: 0; margin-right: 20px" />
        <div>
          <h1>{{ $t('pages.terms_of_service.pending_confirmation.heading') }}</h1>
          <p class="text--grey">
            {{ $t('pages.terms_of_service.pending_confirmation.lead') }}
          </p>

          <p>
            <i18n
              path="pages.terms_of_service.pending_confirmation.email_verification_link"
              tag="span"
            >
              <i class="text--primary">{{ legalContact.email }}</i>
            </i18n><br>
            {{ $t('pages.terms_of_service.pending_confirmation.contact_guardian') }}
          </p>

          <p>
            {{ $t('pages.terms_of_service.pending_confirmation.email_confirmation') }}
          </p>
          <p>
            {{ $t('pages.terms_of_service.pending_confirmation.email_not_received') }}
            <a href="#" @click.prevent="resend">
              {{ $t('global.actions.resend_email') }}
            </a>
          </p>
          <legal-contact-update-modal
            :legal-contact="legalContact"
            @update="legalContact = $event"
          />
        </div>
      </div>
    </template>
    <dashboard-image slot="secondary" name="verification" />
  </t-split-screen>
</template>
<script>
import DashboardImage from '~/components/DashboardImage';
import AcceptTosForm from '~/pages/applicant/tos/partials/AcceptTosForm';
import CreateLegalContactForm from '~/pages/applicant/tos/partials/CreateLegalContactForm';
import LegalContactUpdateModal from '~/pages/applicant/tos/partials/LegalContactUpdateModal';
import LegalContact from '~/models/LegalContact';
import TLoading from '~/components/TLoading';

export default {
  components: {
    TLoading,
    CreateLegalContactForm,
    AcceptTosForm,
    LegalContactUpdateModal,
    DashboardImage,
  },

  layout: 'standalone',

  middleware: [
    'auth',
    'applicant',
    ({ $auth, redirect, $RouteEnum }) => {
      if ($auth.me.tos_accepted) {
        redirect({ name: $RouteEnum.DASHBOARD });
      }
    },
  ],

  data: () => ({
    loading: false,

    legalContact: null,
  }),

  computed: {
    isAdult() {
      const dateOfBirth = this.$dayjs(this.$auth.me.date_of_birth);
      return this.$dayjs().diff(dateOfBirth, 'year') >= 18;
    },
  },

  async beforeMount() {
    if (!this.isAdult) {
      this.loading = true;
      await this.getLegalContact();
      this.loading = false;
    }
  },

  methods: {
    async getLegalContact() {
      try {
        this.legalContact = await LegalContact.$findByApplicantId(this.$auth.me.id);
      } catch (e) {
        // A 404 is expected if the code is not valid.
        const statusCode = e?.response?.status;
        if (statusCode === 404) {
          return;
        }

        this.$axios.handleError(e);
      }
    },

    async onAccepted() {
      await this.$router.pushExpectRedirect({ name: this.$RouteEnum.DASHBOARD });
    },

    onLegalContactCreated(contact) {
      this.legalContact = contact;
    },

    async resend() {
      try {
        await new LegalContact({ id: this.legalContact.id }).resend();
        await this.$notify.success(this.$t('notifications.notification.sent'));
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.t-split-screen::v-deep .t-split-screen__primary {
  position: relative;
}

.logout {
  position: absolute;
  top: $s-4;
  right: $s-4;
}
</style>
