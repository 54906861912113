<template>
  <div class="accept-form">
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper
          :name="$t('fields.terms_of_service')"
          :rules="{ required: { allowFalse: false } }"
          no-label
        >
          <t-checkbox v-model="tosAccepted">
            <i18n path="fields.terms_of_service_accept">
              <a :href="$config.public.service.applicant_tos_url">{{
                $t('fields.terms_of_service')
              }}</a>
            </i18n>
          </t-checkbox>
        </t-validation-wrapper>

        <t-form-element>
          <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
            {{ $t('global.actions.continue') }}
          </t-button-loading>
        </t-form-element>
        <p class="text--grey">
          {{ $t('pages.terms_of_service.no_cost') }}
        </p>
      </t-form>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import Applicant from '~/models/Applicant';

export default Vue.extend({
  components: {
    TValidationWrapper,
  },

  data: () => ({
    tosAccepted: false,

    loading: false,
  }),

  methods: {
    async submit() {
      this.loading = true;
      try {
        // Make request to accept.
        // Probably just an update to applicant.
        const updated = await new Applicant({ id: this.$auth.me.id, tos_accepted: true }).patch();

        this.$auth.commit('PATCH_ME', {
          ...this.$auth.me,
          ...{ tos_accepted: updated.tos_accepted },
        });

        this.$emit('accepted');
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped></style>
