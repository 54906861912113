<template>
  <div class="create-legal-contact-form">
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper
          vid="firstname"
          :name="$t('fields.guardian.firstname')"
          rules="required"
        >
          <t-input
            v-model="form.firstname"
            type="text"
            :placeholder="$t('fields.guardian.firstname')"
          >
            <t-icon slot="right" :icon="['fas', 'user']" />
          </t-input>
        </t-validation-wrapper>
        <t-validation-wrapper
          vid="lastname"
          :name="$t('fields.guardian.lastname')"
          rules="required"
        >
          <t-input
            v-model="form.lastname"
            type="text"
            :placeholder="$t('fields.guardian.lastname')"
          >
            <t-icon slot="right" :icon="['fas', 'user']" />
          </t-input>
        </t-validation-wrapper>
        <t-validation-wrapper
          vid="email"
          :name="$t('fields.guardian.email')"
          rules="required|email"
        >
          <t-input
            v-model="form.email"
            type="email"
            :placeholder="`${$t('fields.guardian.email')}...`"
          >
            <t-icon slot="right" :icon="['far', 'envelope']" />
          </t-input>
        </t-validation-wrapper>
        <t-validation-wrapper
          vid="gender"
          :name="$t('fields.guardian.gender')"
          rules="required|string"
        >
          <gender-select v-model="form.gender" />
        </t-validation-wrapper>
        <t-form-element>
          <t-button-loading native-type="submit" block :loading="loading" :disabled="invalid">
            {{ $t('global.actions.send_verification_link') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import GenderSelect from '~/components/EnumSelect/GenderSelect';
import LegalContact from '~/models/LegalContact';

export default Vue.extend({
  components: {
    GenderSelect,
    TValidationWrapper,
  },

  data: () => ({
    form: null,

    loading: false,
  }),

  beforeMount() {
    this.form = this.getForm();
    this.form.applicant_id = this.$auth.me.id;
  },

  methods: {
    getForm() {
      return {
        firstname: null,
        lastname: null,
        email: null,
        gender: null,
      };
    },

    async submit() {
      this.loading = true;
      try {
        const contact = await new LegalContact({ ...this.form }).save();
        this.$emit('created', contact);
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
