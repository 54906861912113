import Model from '~/models/Model';

export default class LegalContact extends Model {
  resource() {
    return 'v1/legal-contacts';
  }

  async $findByApplicantId(id: string) {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'GET',
      url: `${endpoint}?filter[applicant_id]=${id}`,
    });

    return response?.data?.data[0];
  }

  static $findByApplicantId(id: string) {
    const self = new this();

    return self.$findByApplicantId(id);
  }

  async verify(payload: { token: string }) {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'PUT',
      url: `${endpoint}/verify`,
      data: payload,
    });

    return response?.data?.data;
  }

  async resend() {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'PUT',
      url: `${endpoint}/resend`,
    });

    return response?.data?.data;
  }
}
